import { useEffect } from "react";

import { gtm } from "src/libs/gtm";

export const usePageView = (): void => {
  useEffect(() => {
    // Push the page_view event with additional data
    gtm.push({ event: "page_view",});
  }, []);
};
