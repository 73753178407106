import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";
import ScrollToTop from "src/components/scroll-to-top";

import { Layout as DashboardLayout } from "src/layouts/dashboard";

const IndexPage = lazy(() => import("src/pages/dashboard/index"));
const CustomerListPage = lazy(
  () => import("src/pages/dashboard/customers/list")
);
// const PrintListPage = lazy(
//   () => import("src/pages/dashboard/customers/print-list")
// );
const LearnPage = lazy(() => import("src/pages/dashboard/learn"));
const CustomerCreatePage = lazy(
  () => import("src/pages/dashboard/customers/create")
);
// const PrintCreatePage = lazy(
//   () => import("src/pages/dashboard/customers/print-create")
// );
const CustomerAnalysisPage = lazy(
  () => import("src/pages/dashboard/customers/analysis")
);
const ToolkitPage = lazy(() => import("src/pages/dashboard/toolkit"));
const MarketingPage = lazy(() => import("src/pages/dashboard/marketing"));
const QuizPage = lazy(() => import("src/pages/dashboard/quiz"));
const ReferralsPage = lazy(() => import("src/pages/dashboard/referrals"));
const UpgradePage = lazy(() => import("src/pages/dashboard/upgrade"));
// const CustomerUploadPage = lazy(
//   () => import("src/pages/dashboard/customers/upload")
// );
const AnalysisDetail = lazy(
  () => import("src/pages/dashboard/jobs/companies/analysis")
);
const PlansDetail = lazy(
  () => import("src/pages/dashboard/jobs/companies/plans")
);
const AccountPage = lazy(() => import("src/pages/dashboard/account"));
const TransactionsPage = lazy(() => import("src/pages/dashboard/customers/transactions"));
const ScenariosPage = lazy(() => import("src/pages/dashboard/scenarios"));
const CourseDetailsPage = lazy(() => import("src/pages/dashboard/tutorial"));

export const dashboardRoutes: RouteObject[] = [
  {
    path: "/", // Change this to the desired base path
    element: (
      <DashboardLayout>
        <Suspense>
          <ScrollToTop />
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "clients",
        children: [
          {
            path: "create",
            element: <CustomerCreatePage />,
          },
          {
            path: "analysis",
            element: <CustomerAnalysisPage />,
          },
          // {
          //   path: ":advisorClientId/upload",
          //   element: <CustomerUploadPage />,
          // },
          {
            index: true,
            element: <CustomerListPage />,
          },
          // {
          //   path: "print",
          //   element: <PrintListPage />,
          // },
          // {
          //   path: "print/create",
          //   element: <PrintCreatePage />,
          // },
        ],
      },
      {
        path: "transactions",
        element: <TransactionsPage />,
      },
      {
        path: "account/:tab?",
        element: <AccountPage />,
      },
      {
        path: "scenarios",
        element: <ScenariosPage />,
      },
      {
        path: "tutorials",
        element: <LearnPage />,
      },
      {
        path: "tutorials/:id", // This will handle individual course details
        element: <CourseDetailsPage />, // This is your CourseDetailsPage component
      },
      {
        path: "toolkit",
        element: <ToolkitPage />,
      },
      {
        path: "referrals",
        element: <ReferralsPage />,
      },
      {
        path: "upgrade",
        element: <UpgradePage />,
      },
      {
        path: "marketing",
        element: <MarketingPage />,
      },
      {
        path: "quiz",
        element: <QuizPage />,
      },
      {
        path: "learn/plans",
        element: <PlansDetail />,
      },
      {
        path: "learn/analysis",
        element: <AnalysisDetail />,
      },
    ],
  },
];
