import { lazy } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";

import { IssuerGuard } from "src/guards/issuer-guard";
import { Issuer } from "src/utils/auth";

// JWT
const EntryStartPage = lazy(() => import("src/pages/auth/jwt/entry-start"));
const EntryPage = lazy(() => import("src/pages/auth/jwt/entry"));

export const authRoutes: RouteObject[] = [
  {
    path: "auth",
    element: (
      <IssuerGuard issuer={Issuer.JWT}>
            <Outlet />
      </IssuerGuard>
    ),
    children: [

      {
        path: "entry/start",
        element: <EntryStartPage />,
      },
      {
        path: "entry",
        element: <EntryPage />,
      },
    ],
  },
];
