import ReactDOM from "react-dom/client";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { App } from "src/app";
import ErrorBoundary from "./components/error-boundary";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
