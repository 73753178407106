import { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import useWhiteLabelConfig from "src/contexts/snug/config-context";
import { CircularProgress } from "@mui/material";

export function SplashScreen() {
  const { wl_professional_group } = useWhiteLabelConfig();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (imageRef.current && wl_professional_group?.app_logo) {
        const { naturalWidth, naturalHeight } = imageRef.current;
        const aspectRatio = naturalWidth / naturalHeight;
        const maxWidth = 300;
        const maxHeight = 40;

        if (naturalWidth > maxWidth || naturalHeight > maxHeight) {
          if (maxWidth / aspectRatio < maxHeight) {
            setDimensions({ width: maxWidth, height: maxWidth / aspectRatio });
          } else {
            setDimensions({
              width: maxHeight * aspectRatio,
              height: maxHeight,
            });
          }
        } else {
          setDimensions({ width: naturalWidth, height: naturalHeight });
        }
      }
    };

    const img = imageRef.current;

    if (img && wl_professional_group?.app_logo) {
      img.onload = handleResize;
      img.src = wl_professional_group?.app_logo;
    }

    return () => {
      if (img) {
        img.onload = null;
      }
    };
  }, [wl_professional_group?.app_logo]);

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "background.paper",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        left: 0,
        top: 0,
        width: "100vw",
        zIndex: 1400,
        position: "fixed",
      }}
    >
      <Box sx={{ maxWidth: 300, maxHeight: 40, display: "inline-flex" }}>
        {/* if logo is not present, present circular progress in center of screen same size */}
        {wl_professional_group?.app_logo ? (
          <img
            ref={imageRef}
            src={wl_professional_group?.app_logo || undefined}
            style={{ width: dimensions.width, height: dimensions.height }}
          />
        ) : (
          <CircularProgress
            sx={{ color: "primary.main" }}
            size={30}
            thickness={4}
          />
        )}
      </Box>
    </Box>
  );
}
