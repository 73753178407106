import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { Theme } from "@mui/material/styles/createTheme";

import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { paths } from "src/paths";
import useWhiteLabelConfig from "src/contexts/snug/config-context";
import useUserDataProvider from "src/contexts/snug/userData-context";

const Page = () => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { wl_professional_group } = useWhiteLabelConfig();

  usePageView();

  const {
    professionalGroup,
  } = useUserDataProvider();

  return (
    <>
      <Seo title={`Pro - ${!professionalGroup?.professional_payment_tier ? `Demo - ` : ``}401`} />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          py: "80px",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 6,
            }}
          >
            <Box
              alt="Not found"
              component="img"
              src={
                wl_professional_group?.app_logo ||
                "https://s3.us-east-2.amazonaws.com/assets.getsnug.com/files/snug_logo.png"
              }
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 160,
              }}
            />
          </Box>
          <Typography align="center" variant={mdUp ? "h1" : "h4"}>
            401
          </Typography>
          <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
            Authorization required
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button component={RouterLink} href={paths.index}>
              Back to Home
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Page;
