import type { FC } from "react";
import Box from "@mui/material/Box";
import useWhiteLabelConfig from "src/contexts/snug/config-context";

export const LoginDenied: FC = () => {
  const { wl_professional_group } = useWhiteLabelConfig();

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "background.paper",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        left: 0,
        top: 0,
        width: "100vw",
        zIndex: 1400,
      }}
    >
      <Box sx={{ maxWidth: 150, display: "inline-flex" }}>
        <img
          src={
            wl_professional_group?.app_logo ||
            "https://s3.us-east-2.amazonaws.com/assets.getsnug.com/files/snug_logo.png"
          }
          style={{ width: "100%" }}
        />
      </Box>
      <Box sx={{ color: "red" }}>
        You are not allowed to access this dashboard. You may need to register
        to access the snug professional dashboard.{" "}
        <a href="https://dashboard.getsnug.com/auth/start?ep=register">
          Go here to Sign Up
        </a>
      </Box>
    </Box>
  );
};
