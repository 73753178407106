import { Typography, Button } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { ErrorInfo, ReactNode } from "react";
import { paths } from "src/paths";
import { slackHook } from "src/utils/snug/slackHooks";
import { RouterLink } from "./router-link";
import { Seo } from "./seo";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  retryAttempted: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      retryAttempted: this.getRetryAttempt(),
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, retryAttempted: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    slackHook(`ERROR BOUNDARY - ${error.message}`);

    if (!this.state.retryAttempted) {
      this.setRetryAttempt(true);
      this.setState({ retryAttempted: true }, () => {
        window.location.reload();
      });
    }
  }

  getRetryAttempt() {
    return sessionStorage.getItem("retryAttempted") === "true";
  }

  setRetryAttempt(attempted: boolean) {
    sessionStorage.setItem("retryAttempted", attempted.toString());
  }

  render() {
    if (this.state.hasError && this.state.retryAttempted) {
      return (
        <>
          <Seo title={`Pro - Error`} />
          <Box
            component="main"
            sx={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              py: "80px",
            }}
          >
            <Container maxWidth="lg">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mb: 6,
                }}
              >
                <Box
                  alt="Not found"
                  component="img"
                  src={"/assets/errors/error-500.png"}
                  sx={{
                    height: "auto",
                    maxWidth: "100%",
                    width: 160,
                  }}
                />
              </Box>
              <Typography align="center" variant={"h4"}>
                Server Error
              </Typography>
              <Typography
                align="center"
                color="text.secondary"
                sx={{ mt: 0.5 }}
              >
                Please refresh the page or try again later. We've been notified
                of this error.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 6,
                }}
              >
                <Button component={RouterLink} href={paths.index}>
                  Back to Home
                </Button>
              </Box>
            </Container>
          </Box>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
