import { useEffect, useState, type FC, type ReactNode } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import type { Theme } from "@mui/material/styles/createTheme";

import type { NavColor } from "src/types/settings";

import type { Section } from "../config";
import { MobileNav } from "../mobile-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import useUserDataProvider from "src/contexts/snug/userData-context";
import { PopupModal } from "react-calendly";
import { useSections } from "../config";

const HorizontalLayoutRoot = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
});

const HorizontalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

interface HorizontalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: Section[];
}

export const HorizontalLayout: FC<HorizontalLayoutProps> = (props) => {
  const { children, navColor } = props;
  const sections = useSections();

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();
  const { professionalGroup, userData } = useUserDataProvider();
  const [demoIsOpen, setDemoIsOpen] = useState(false);

  useEffect(() => {
    const showDemoPopup =
      professionalGroup?.show_demo_popup &&
      !professionalGroup?.professional_payment_tier;
    const demoPopupShown = sessionStorage.getItem("demoPopupShown");
    if (showDemoPopup && !demoPopupShown) {
      setDemoIsOpen(true);
      sessionStorage.setItem("demoPopupShown", "true");
    }
  }, [professionalGroup]);

  return (
    <>
      <TopNav
        color={navColor}
        onMobileNav={mobileNav.handleOpen}
        sections={sections}
      />
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <HorizontalLayoutRoot>
        <HorizontalLayoutContainer>{children}</HorizontalLayoutContainer>
      </HorizontalLayoutRoot>
      <PopupModal
        url="https://calendly.com/d/cpmw-y6c-8pq?hide_gdpr_banner=1&utm_source=dashboard"
        onModalClose={() => setDemoIsOpen(false)}
        open={demoIsOpen}
        prefill={{
          email: userData?.contact_email,
          name: userData?.full_name,
        }}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")!}
      />
    </>
  );
};

HorizontalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf<NavColor>(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
