const axios = require("axios").default;

export const slackHookApi = axios.create({
  baseURL: "https://hooks.slack.com",
});

const config = {
  withCredentials: false,
  transformRequest: [
    (data: any, headers: any) => {
      delete headers.post["Content-Type"];
      return data;
    },
  ],
};

const slackHookKeys = {
  staging: process.env.REACT_APP_SLACK_STAGING_KEY,
  prod: process.env.REACT_APP_SLACK_PROD_KEY,
};

export const slackHook = async (payload: string) => {
  const env = process.env.REACT_APP_SNUG_ENV;
  if (env !== "LOCAL") {
    let urlKey = `B04J086AM0Q/${slackHookKeys.staging}`;
    if (env === "PRODUCTION") {
      urlKey = `B04HAD4UXJP/${slackHookKeys.prod}`;
    }
    slackHookApi
      .post(
        `services/TNT1LFQ1F/${urlKey}`,
        JSON.stringify({ text: 'Dashboard: ' + payload }),
        config
      )
      .then((res: any) => res)
      .catch((error: any) =>
        console.error(
          `Failed to notify slack channel of document status: ${error}`
        )
      );
  }
};
