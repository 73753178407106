import type { ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";

import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import useUserDataProvider from "src/contexts/snug/userData-context";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  const { t } = useTranslation();
  const { isWhiteLabel, isCobranding, isFirmAdmin, isProTier } =
    useUserDataProvider();
  const { professionalGroup } = useUserDataProvider();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: `Home`,
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
          ...(!professionalGroup?.onboarding_completed_at
            ? [
                {
                  title: `Upgrade to Pro`,
                  path: paths.dashboard.upgrade,
                  icon: (
                    <SvgIcon fontSize="small">
                      <AutoAwesomeOutlinedIcon />
                    </SvgIcon>
                  ),
                },
              ]
            : []),
          ...(isFirmAdmin && !isProTier
            ? [
                {
                  title: `Refer Friends`,
                  path: paths.dashboard.referrals,
                  icon: (
                    <SvgIcon fontSize="small">
                      <CardGiftcardOutlinedIcon />
                    </SvgIcon>
                  ),
                },
              ]
            : []),
          {
            title: t(tokens.nav.account),
            path: paths.dashboard.account,
            icon: (
              <SvgIcon fontSize="small">
                <ManageAccountsOutlinedIcon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: `Clients`,
        items: [
          {
            title: `Add Client`,
            path: paths.dashboard.clientRoles.create,
            icon: (
              <SvgIcon fontSize="small">
                <GroupAddOutlinedIcon />
              </SvgIcon>
            ),
          },
          ...(professionalGroup?.analysis_on
            ? [
                {
                  title: `Analyze Documents`,
                  path: paths.dashboard.clientRoles.analysis,
                  icon: (
                    <SvgIcon fontSize="small">
                      <InsightsOutlinedIcon />
                    </SvgIcon>
                  ),
                },
              ]
            : []),
          {
            title: `Client List`,
            path: paths.dashboard.clientRoles.index,
            icon: (
              <SvgIcon fontSize="small">
                <GroupOutlinedIcon />
              </SvgIcon>
            ),
          },
          ...(!isProTier
            ? [
                {
                  title: `Transactions`,
                  path: paths.dashboard.transactions,
                  icon: (
                    <SvgIcon fontSize="small">
                      <ReceiptLongOutlinedIcon />
                    </SvgIcon>
                  ),
                },
              ]
            : []),
          ...(!isWhiteLabel || !isCobranding
            ? []
            : [
                {
                  title: `Leads List`,
                  path: paths.dashboard.leads,
                  icon: (
                    <SvgIcon fontSize="small">
                      <GroupsOutlinedIcon />
                    </SvgIcon>
                  ),
                },
              ]),
          // {
          //   title: `Print & Ship`,
          //   path: paths.dashboard.clientRoles.print,
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <PrintOutlinedIcon />
          //     </SvgIcon>
          //   ),
          // },
        ].filter(Boolean) as Item[],
      },
      {
        subheader: `Resources`,
        items: [
          ...(isFirmAdmin || !professionalGroup?.dashboard_wl_on
            ? [
                {
                  title: `Tutorials`,
                  path: paths.dashboard.tutorials,
                  icon: (
                    <SvgIcon fontSize="small">
                      <SchoolOutlinedIcon />
                    </SvgIcon>
                  ),
                },
              ]
            : []),
          {
            title: `Scenarios`,
            path: paths.dashboard.scenarios,
            icon: (
              <SvgIcon fontSize="small">
                <AccountTreeOutlinedIcon />
              </SvgIcon>
            ),
          },
          ...(isFirmAdmin || !professionalGroup?.dashboard_wl_on
            ? [
                {
                  title: `Pro Toolkit`,
                  path: paths.dashboard.toolkit,
                  icon: (
                    <SvgIcon fontSize="small">
                      <BookmarkBorderOutlinedIcon />
                    </SvgIcon>
                  ),
                },
              ]
            : []),
          ...(!isProTier
            ? [
                {
                  title: `Marketing`,
                  path: paths.dashboard.marketing,
                  icon: (
                    <SvgIcon fontSize="small">
                      <CampaignOutlinedIcon />
                    </SvgIcon>
                  ),
                },
              ]
            : []),
          {
            title: `Will vs. Trust Quiz`,
            path: paths.dashboard.quiz,
            icon: (
              <SvgIcon fontSize="small">
                <QuizOutlinedIcon />
              </SvgIcon>
            ),
          },
        ],
      },
    ];
  }, [
    t,
    isWhiteLabel,
    isCobranding,
    isFirmAdmin,
    isProTier,
    professionalGroup?.dashboard_wl_on,
    professionalGroup?.analysis_on,
    professionalGroup?.onboarding_completed_at
  ]);
};
