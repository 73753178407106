import toast from "react-hot-toast";

export interface ContextValues {
  error: string;
  initialLoading: boolean;
  savingNewValues: boolean;
  deletingEntity?: boolean;
}

export const authorizationRolesArray = ["AUTH_READONLY", "AUTH_COLLABORATOR"];

export const professoinalRoleMap = {
  PRO_GROUP_ADMIN: "Admin",
  PRO_GROUP_AGENT: "Agent",
}

export const permissionMap = {
  AUTH_COLLABORATOR: "Collaborator",
  AUTH_READONLY: "Read Only",
}

export const roleMap = {
  CHILD: "Child",
  STEP_CHILD: "Step Child",
  OTHER_DEPENDENT: "Dependent",
  RELATIVE: "Relative",
  SPOUSE: "Spouse",
  PARENT: "Parent",
  GRANDPARENT: "Grandparent",
  OTHER: "Other",
  CHARITY: "Charity",
  SIBLING: "Sibling",
  FRIEND: "Friend",
  PET: "Pet",
  GRANDCHILD: "Grandchild",
  PRIMARY_CARE_PHYSICIAN: "Physician",
  MEDICAL_SPECIALIST: "Medical Specialist",
  DENTIST: "Dentist",
  THERAPIST: "Therapist",
  MEDICAL_OTHER: "Medical",
  FINANCIAL_ADVISOR: "Financial Advisor",
  ATTORNEY: "Attorney",
  ACCOUNTANT: "Accountant",
  INSURANCE_BROKER: "Insurance",
  REAL_ESTATE_AGENT: "Real Estate",
  PROFESSIONAL_OTHER: "Professional",
}

export const estatePlanningRoleMap = {
  FINANCIAL_POWER_OF_ATTORNEY_AGENT: "Power of Attorney Agent",
  CHILD_GUARDIAN: "Guardian",
  HEALTHCARE_DIRECTIVE_AGENT: "Healthcare Agent",
  PET_GUARDIAN: "Pet Caretaker",
  TRUSTEE: "Successor Trustee",
  EXECUTOR: "Executor",
  CUSTOM_PLAN_BENEFICIARY: "Beneficiary",
  GIFT_RECIPIENT: "Gift Recipient",
}

export type RelationshipRoleTypes =
  | "CHILD"
  | "STEP_CHILD"
  | "OTHER_DEPENDENT"
  | "RELATIVE"
  | "SPOUSE"
  | "PARENT"
  | "GRANDPARENT"
  | "OTHER"
  | "CHARITY"
  | "SIBLING"
  | "FRIEND"
  | "PET"
  | "GRANDCHILD"
  | null
  | undefined;

export const professionalRoleTypesArray = [
  "PRIMARY_CARE_PHYSICIAN",
  "MEDICAL_SPECIALIST",
  "DENTIST",
  "THERAPIST",
  "MEDICAL_OTHER",
  "FINANCIAL_ADVISOR",
  "ATTORNEY",
  "ACCOUNTANT",
  "INSURANCE_BROKER",
  "REAL_ESTATE_AGENT",
  "PROFESSIONAL_OTHER",
];

export const financialProfessionalRoleTypesArray = [
  "FINANCIAL_ADVISOR",
  "ATTORNEY",
  "ACCOUNTANT",
  "INSURANCE_BROKER",
  "REAL_ESTATE_AGENT",
  "PROFESSIONAL_OTHER",
];

export const medicalProfessionalRoleTypesArray = [
  "PRIMARY_CARE_PHYSICIAN",
  "MEDICAL_SPECIALIST",
  "DENTIST",
  "THERAPIST",
  "MEDICAL_OTHER",
];

export const humanRelationshipArray = [
  "SPOUSE",
  "CHILD",
  "STEP_CHILD",
  "OTHER_DEPENDENT",
  "RELATIVE",
  "PARENT",
  "GRANDPARENT",
  "OTHER",
  "SIBLING",
  "FRIEND",
  "GRANDCHILD",
  "CHARITY",
];

export const estatePlanningRolesArray = [
  "FINANCIAL_POWER_OF_ATTORNEY_AGENT",
  "CHILD_GUARDIAN",
  "HEALTHCARE_DIRECTIVE_AGENT",
  "PET_GUARDIAN",
  "TRUSTEE",
  "EXECUTOR",
  "CUSTOM_PLAN_BENEFICIARY",
  "GIFT_RECIPIENT",
];

// create concatenated array of roles starting with humanRelationshipArray and then professionalRoleTypesArray and then estatePlanningRolesArray
export const allRoleTypesArray = [
  ...humanRelationshipArray,
  ...professionalRoleTypesArray,
  ...estatePlanningRolesArray,
];

export const relationshipMap = {
  child: "Child",
  step_child: "Step Child",
  other_dependent: "Other Dependent",
  relative: "Relative",
  spouse: "Spouse",
  parent: "Parent",
  grandparent: "Grandparent",
  other: "Other",
  charity: "Charity",
  sibling: "Sibling",
  friend: "Friend",
  pet: "Pet",
  grandchild: "Grandchild",
};

export const maritalStatusMap = {
  married: "Married",
  domestic_partnership: "Domestic Partnership",
  civil_union: "Civil Union",
};

export const dependentsRelationshipArray = [
  "child",
  "step_child",
  "other_dependent",
];



export const timeAgo = (timestamp: Date | null) => {
  if (!timestamp) return "";
  const createdAt = new Date(timestamp);
  const now = new Date();
  const diff = now.getTime() - createdAt.getTime();
  const diffDays = Math.floor(diff / (1000 * 3600 * 24));
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);

  if (diffYears > 0) {
    return `${diffYears} year${diffYears === 1 ? "" : "s"} ago`;
  } else if (diffMonths > 0) {
    return `${diffMonths} month${diffMonths === 1 ? "" : "s"} ago`;
  } else {
    return `${diffDays} day${diffDays === 1 ? "" : "s"} ago`;
  }
};

export const downloadFileFromS3 = async (presignedUrl: string, fileName: string) => {
  try {
    // Fetch the file content from the presigned URL
    const fileResponse = await fetch(presignedUrl);
    const blob = await fileResponse.blob();

    // Create an anchor element and set the attributes
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  } catch (error) {
    toast.error(
      "There was an error downloading the file. Please try again later."
    );
  }
};

// src/utils/setFavicons.ts
export const setFavicons = (): void => {
  const currentDomain = window.location.hostname;

  const isDefaultDomain =
    currentDomain === process.env.REACT_APP_DEFAULT_SNUG_DOMAIN;
  const faviconPath = isDefaultDomain ? "snug-" : "wl-";

  const favicons = [
    { rel: "icon", sizes: "16x16", href: `${faviconPath}favicon-16x16.png` },
    { rel: "icon", sizes: "32x32", href: `${faviconPath}favicon-32x32.png` },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: `${faviconPath}apple-touch-icon.png`,
    },
    { rel: "shortcut icon", href: `${faviconPath}favicon.ico` },
  ];

  // Remove existing favicons
  const links = document.querySelectorAll<HTMLLinkElement>(
    'link[rel*="icon"], link[rel="apple-touch-icon"]'
  );
  links.forEach((link) => link.parentNode?.removeChild(link));

  // Add new favicons
  favicons.forEach((favicon) => {
    const link = document.createElement("link");
    link.rel = favicon.rel;
    if (favicon.sizes) {
      link.setAttribute("sizes", favicon.sizes);
    }
    link.href = favicon.href;
    document.head.appendChild(link);
  });
};