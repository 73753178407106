import { useCallback } from "react";

const axios = require("axios").default;

const snugAuthBaseURL = process.env.REACT_APP_SNUG_AUTH_URL;

export enum AuthGenericPath {
  SIGNUP = "user/create/",
}

interface HasId {
  id: string | number | null;
}

export const auth_api_instance = axios.create({
  baseURL: snugAuthBaseURL,
});

export const signUp = async (email: string, password: string, name: string) => {
  const response = await auth_api_instance.post(AuthGenericPath.SIGNUP, {
    email: email.toLowerCase(),
    password: password,
    first_name: name,
  });
  return response.data.data;
};

export const sendVerifyEmailEmail = async (
  username: string,
  pwBypass?: boolean
) => {
  const response = await auth_api_instance.post("user/email-verify/", {
    username: username.toLowerCase(),
    pwBypass: pwBypass,
  });
  return response;
};
export const emailVerify = async (
  token: string,
  password: string | null,
  first_name: string | null
) => {
  const response = await auth_api_instance.put("user/email-verify/", {
    token: token,
    password: password,
    first_name: first_name,
  });
  return response;
};

export const getUser = async (token: string) => {
  const config = {
    headers: { Authorization: "Bearer " + token },
  };
  const response = await auth_api_instance.get("user/detail/", config);
  return response.data;
};

export const getToken = async (email: string, password: string) => {
  const response = await auth_api_instance.post("token/", {
    username: email.toLowerCase(),
    password: password,
  });
  return response;
};

export const verifyToken = async (
  token: string,
  controller: AbortController
) => {
  const response = await auth_api_instance.post(
    "token/verify/",
    { token: token },
    { signal: controller.signal }
  );
  return response;
};

export const refreshToken = async (refresh: string) => {
  const response = await auth_api_instance.post("token/refresh/", {
    refresh: refresh,
  });
  return response;
};

export const resetPassWordTokenAndEmail = async (email: string) => {
  const response = await auth_api_instance.post("password_reset/", {
    email: email.toLowerCase(),
    redirect_app_domain: window.location.origin.replace('https://', '').replace('http://', '') || process.env.REACT_APP_DEFAULT_SNUG_DOMAIN,
  });
  return response;
};

export const resetPassword = async (token: string, newPassword: string) => {
  const response = await auth_api_instance.post("password_reset/confirm/", {
    token: token,
    password: newPassword,
  });
  return response;
};

//This is not currently used as we pass password changes through reset password.
export const changePassword = async () => {
  let config = null;
  if (localStorage.getItem("s_at")) {
    config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("s_at"),
      },
    };
  }
  return await auth_api_instance.post("password_change/", null, config);
};

export const useAddOrUpdateData = <T extends HasId>(path: string) => {
  const addOrUpdateData = useCallback(
    async (data: T): Promise<T> => {
      if (data) {
        if (data["id"] && data["id"] !== "") {
          const response = await auth_api_instance.put(path, data);
          return response.data.data as T;
        } else {
          const response = await auth_api_instance.post(path, data);
          return response.data.data as T;
        }
      }
      return Promise.reject();
    },
    [path]
  );

  return addOrUpdateData;
};

export const useGenericGetData = <T,>(
  path: string,
  readOnlyId?: number | null
) => {
  const genericGetData = useCallback(
    async (params?: any): Promise<T> => {
      const response = await auth_api_instance.get(
        readOnlyId ? path.replace("v2", `v2/ro/${readOnlyId}`) : path,
        {
          params: params,
        }
      );
      return response.data.data as T;
    },
    [path, readOnlyId]
  );

  return genericGetData;
};

auth_api_instance.interceptors.request.use((config: any) => {
  if (localStorage.getItem("s_at")) {
    config.headers.Authorization = "Bearer " + localStorage.getItem("s_at");
  }
  return config;
});
